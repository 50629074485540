var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fixed-relation" }, [
    !_vm.hideSearch
      ? _c(
          "div",
          { staticClass: "fixed-search" },
          [
            _c("el-autocomplete", {
              attrs: {
                "fetch-suggestions": _vm.searchRemote,
                placeholder: "请输入...",
              },
              on: { select: _vm.searchSelect },
              model: {
                value: _vm.searchObj.value,
                callback: function ($$v) {
                  _vm.$set(_vm.searchObj, "value", $$v)
                },
                expression: "searchObj.value",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.btnText
      ? _c(
          "div",
          { staticClass: "fixed-btn" },
          [
            _c("el-button", { on: { click: _vm.clickBtn } }, [
              _vm._v(_vm._s(_vm.btnText)),
            ]),
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "tencent-map", attrs: { id: "tencent-map" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }