<template>
  <div class="fixed-relation">
    <div class="fixed-search" v-if="!hideSearch">
      <el-autocomplete v-model="searchObj.value"
                       :fetch-suggestions="searchRemote"
                       placeholder="请输入..."
                       @select="searchSelect">
      </el-autocomplete>
    </div>
    <div class="fixed-btn" v-if="btnText">
      <el-button @click="clickBtn">{{btnText}}</el-button>
    </div>
    <div id="tencent-map" class="tencent-map"></div>
  </div>
</template>

<script>

const appKey = process.env.VUE_APP_TENCENTMAP_KEY;
const isNumber = (result) => Object.prototype.toString.call(result) === '[object Number]';

export default {
  props: {
    hideSearch: {
      type: Boolean,
      default: false,
    },
    // 按钮文字
    btnText: {
      type: String,
      default: '',
    },
    // 范围圆圈的默认值
    circleRadio: {
      type: Number,
      default: 0,
    },
    lat: String,
    lng: String,
  },
  watch: {
    lat: {
      immediate: true,
      handler(val) {
        console.log(val);
      },
    },
    circleRadio: {
      handler(curVal) {
        this.moveCircleRadius(curVal);
      },
    },
  },
  data() {
    return {
      searchObj: {
        value: '',
        list: [],
      },
      TMap: null,
      map: null,
      geocoder: null,
      marker: null,
      markCenter: null,
    };
  },
  methods: {
    /** @desc 点击按钮 */
    clickBtn() {
      this.$emit('clickBtn');
    },
    /** @desc 远程搜索 */
    searchRemote(query, callback) {
      if (!query) return callback([]);
      const url = 'https://apis.map.qq.com/ws/place/v1/suggestion';
      this.$jsonp(url, {
        keyword: query,
        key: appKey,
        output: 'jsonp',
      }).then((res) => {
        if (!res || res.data.length === 0) return;
        callback(res.data.map((v) => ({ ...v, value: v.address })));
      }).catch((error) => {
        console.log('腾讯地图API远程地址搜索失败=======', error);
      });
    },
    /** @desc 选中搜索后的值 */
    searchSelect(item) {
      if (!item) return;
      console.log('searchSelect======', item);
      this.moveMap(item.location.lat, item.location.lng);
      this.moveMaker(item.location.lat, item.location.lng);
      this.moveCircleCenter(item.location.lat, item.location.lng);
      this.emitAddress({
        title: item.title,
        address: item.address,
        lat: item.location.lat,
        lng: item.location.lng,
      });
    },
    /** @desc  异步加载腾讯地图 */
    syncLoadTMap() {
      return new Promise((resolve, reject) => {
        if (window.qq && window.qq.maps) {
          this.TMap = window.qq.maps;
          resolve(window.qq.maps);
        } else {
          const scriptMap = document.createElement('script');
          scriptMap.type = 'text/javascript';
          scriptMap.async = true;
          scriptMap.src = `https://map.qq.com/api/js?v=2.exp&key=${appKey}&callback=initTencentMap`;
          scriptMap.onerror = reject;
          scriptMap.dataTitle = 'tencentMap';
          document.body.appendChild(scriptMap);

          window.initTencentMap = () => {
            this.TMap = window.qq.maps;
            resolve(window.qq.maps);
          };
        }
      });
    },
    /** @desc 初始化地图 */
    initMap() {
      console.log(this.lat, this.lng);
      if (!this.TMap) return;
      const that = this;
      this.map = new this.TMap.Map(document.getElementById('tencent-map'), {
        zoom: 16, // 设置地图缩放级别
        scrollwheel: false, // 禁用滚动
        zoomControl: true,
        center: new that.TMap.LatLng(that.lat || 39.984104, that.lng || 116.307503),
      });
    },
    /** @desc 移动中心点 */
    moveCenter(lat, lng) {
      if (!this.TMap) return;
      const that = this;
      const latLng = new this.TMap.LatLng(lat, lng);
      if (!that.marker) {
        that.marker = new that.TMap.Marker({
          position: latLng,
          map: that.map,
        });
        return;
      }
      that.moveMap(lat, lng);
      that.moveMaker(lat, lng);
      that.moveCircleCenter(lat, lng);
      that.analysisLatLngToAddress(lat, lng, true);
    },
    /** @desc 初始化城市位置查询 */
    initCityLocation() {
      if (!this.TMap) return;
      const that = this;
      if (this.lng && this.lat) {
        that.moveMap(that.lat, that.lng);
        that.moveMaker(that.lat, that.lng);
        that.moveCircleRadius(that.circleRadio);
        that.moveCircleCenter(that.lat, that.lng);
        that.analysisLatLngToAddress(that.lat, that.lng, true);
      } else {
        this.cityLocation = new this.TMap.CityService({
          complete(result) {
            that.moveMap(result.detail.latLng.lat, result.detail.latLng.lng);
            that.moveMaker(result.detail.latLng.lat, result.detail.latLng.lng);
            that.moveCircleRadius(that.circleRadio);
            that.moveCircleCenter(result.detail.latLng.lat, result.detail.latLng.lng);
            that.analysisLatLngToAddress(result.detail.latLng.lat, result.detail.latLng.lng, true);
          },
        });
        this.cityLocation.searchLocalCity();
      }
    },
    /** @desc 初始化坐标点Marker */
    initMaker() {
      if (!this.TMap) return;
      const that = this;
      this.marker = new this.TMap.Marker({
        map: that.map,
      });
    },
    /** @desc 初始化经纬度和地址转换类 */
    initGeocoder() {
      if (!this.TMap) return;
      this.geocoder = new this.TMap.Geocoder();
    },
    /** @desc 初始化地图事件 */
    initEvent() {
      if (!this.TMap) return;
      const that = this;
      this.TMap.event.addListener(this.map, 'click', (result) => {
        if (!that.marker) {
          that.marker = new that.TMap.Marker({
            position: result.latLng,
            map: that.map,
          });
          return;
        }
        that.moveMap(result.latLng.lat, result.latLng.lng);
        that.moveMaker(result.latLng.lat, result.latLng.lng);
        that.analysisLatLngToAddress(result.latLng.lat, result.latLng.lng);
        that.moveCircleCenter(result.latLng.lat, result.latLng.lng);
      });
    },
    /** @desc 初始化原型覆盖物 */
    initCircle() {
      if (!this.TMap) return;
      console.log('initCircle======', this.circleRadio);
      this.circle = new this.TMap.Circle({
        map: this.map,
        radius: this.circleRadio,
        visible: true,
        strokeWeight: 2,
        fillColor: new this.TMap.Color(177, 207, 239, 0.3),
        strokeColor: new this.TMap.Color(37, 147, 252, 1),
      });
    },
    /** @desc 移动地图 */
    moveMap(lat, lng) {
      if (!this.map) return;
      // todo 通过单独实例化经纬度类，方便该组件外部调用
      const latLng = new this.TMap.LatLng(lat, lng);
      this.map.panTo(latLng);
    },
    /** @desc 移动Marker */
    moveMaker(lat, lng) {
      if (!this.marker) return;
      const latLng = new this.TMap.LatLng(lat, lng);
      this.marker.setPosition(latLng);
    },
    /** @desc 设置范围的半径 */
    moveCircleRadius(radius) {
      if (!this.circle) return;
      this.circle.setRadius(radius);
    },
    /** @desc 设置范围的中心店 */
    moveCircleCenter(lat, lng) {
      if (!this.circle) return;
      const latLng = new this.TMap.LatLng(lat, lng);
      this.circle.setCenter(latLng);
    },
    /** @desc 经纬度转地址 */
    analysisLatLngToAddress(lat, lng, initAddress) {
      if (!this.geocoder) return;
      const that = this;
      this.geocoder.setComplete((result) => {
        const detail = result.detail || {};
        const address = {
          title: detail.nearPois && detail.nearPois.length > 0 ? detail.nearPois[0].name : '',
          address: detail.address,
          lat,
          lng,
        };
        if (initAddress) {
          // 初始化时候获取地址信息
          that.emitInitAddress(address);
        } else {
          that.emitAddress(address);
        }
      });
      this.geocoder.setError((error) => { console.log('analysisLatLngToAddress====解析失败', error); });
      const latLng = new this.TMap.LatLng(lat, lng);
      this.geocoder.getAddress(latLng);
    },
    /** @desc 地址转经纬度 */
    analysisAddressToLatLng(address) {
      if (!this.geocoder) return;
      const that = this;
      this.geocoder.setComplete((result) => {
        const { lat, lng } = result.detail.location;
        that.moveMap(lat, lng);
        that.moveMaker(lat, lng);
        that.moveCircleCenter(lat, lng);
      });
      this.geocoder.setError((error) => {
        that.$message.warning('地址解析失败:请输入正确的地址!');
      });
      this.geocoder.getLocation(address);
    },
    emitAddress(address) {
      this.$emit('addressChange', address);
    },
    emitInitAddress(address) {
      this.$emit('addressInit', address);
    },
  },
  async mounted() {
    await this.syncLoadTMap();
    this.initMap();
    this.initMaker();
    this.initGeocoder();
    this.initCircle();
    this.initEvent();
    this.initCityLocation();
  },
  beforeDestroy() {
    this.TMap = null;
    this.map = null;
    this.geocoder = null;
    this.marker = null;
    this.markCenter = null;
  },
};
</script>

<style lang="less" scoped>
.tencent-map {
  width: 100%;
  min-height: 300px;
  height: 300px;
  position: relative;
}
.fixed-relation {
  position: relative;
}
.fixed-search {
  // width: 40%;
  position: absolute;
  left: 10px;
  top: 10px;
  width: 30%;
  z-index: 9999;
}
.fixed-btn{
  position: absolute;
  top: 10px;
  left: calc(30% + 20px);
  z-index: 9999;
}
/deep/ .el-autocomplete{
  width: 100%;
}

/deep/ .smnoprint{
  display: none;
}
</style>
